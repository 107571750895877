import React from 'react'
import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'react-redux'
import {createRoot} from 'react-dom/client';
import configureStore from './configureStore'
import moment from 'moment'
import {Url} from "finhelper"
import {NotifyManager} from "notify-component";
import {Api, ApiRequest, Builder} from "laravel-request";
import PagesRouter from './pagesRouter/pagesRouter'

import "moment/locale/ru";

import './assets/css/normalize.css'
import './assets/css/main.css'
import './assets/css/treemap.css'
import './assets/css/tactics.css'
import './assets/css/case.css'
import './assets/css/bigcase.css'
import './assets/css/balance.css'
import './assets/css/insurance.css'
import './assets/css/rc-slider.css'
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import './assets/css/popup.css'
import LocalStorageHelper from "#app/helpers/Cache/LocalStorageHelper";

Error.stackTraceLimit = 30;

ApiRequest.notifyClass = NotifyManager;

Builder.availableMethod = [
  ...Builder.availableMethod,
  ...[
    'whereAccount',
    'whereIncomeType',
    'whereSalaryType',
    'whereSpendingType',
    'whereObligationType',
    'wherePropertyType',
    'whereInvestType',
    'whereCurrencyType',
    'whereAccountType',
    'whereInsuranceType',
  ]
]

const logMessage = async () => {
  try
  {
    if (window.performance && window.performance.memory) {
      console.log("Memory Usage:", window.performance.memory.usedJSHeapSize / (1024 * 1024), "MB");
      console.log("Total Memory:", window.performance.memory.totalJSHeapSize / (1024 * 1024), "MB");
      console.log("Heap Size Limit:", window.performance.memory.jsHeapSizeLimit / (1024 * 1024), "MB");
    } else {
      console.log("Memory API not supported in this browser.");
    }

    const quota = await window.navigator.storage.estimate();

    console.log("Total space for storage", quota.quota / (1024 * 1024), "MB");
    console.log("Usage space of storage", quota.usage / (1024 * 1024), "MB");
  }catch (e){
    console.warn(e)
  }
}

logMessage();
setInterval( () => {
  logMessage();
}, 1000 * 60)

// am4core.useTheme(am4themes_animated);
// am4core.options.minPolylineStep = 5;

const setLanguage = (langId, locale) => {
  localStorage.setItem(LocalStorageHelper.LANGUAGE, langId);
  moment.locale(locale);
};

const url = Url.getCurrentUrl();
const lang = localStorage.getItem(LocalStorageHelper.LANGUAGE);

if (url.includes('/ru/')) {
  setLanguage(1, 'ru');
  window.i18n = {};
} else if (url.includes('/en/')) {
  setLanguage(2, 'en');
} else if (parseInt(lang) === 1 || lang === null) {
  moment.locale('ru');
  window.i18n = {};
} else {
  moment.locale('en');
}

String.prototype.isNumber = function(){return /^\d+$/.test(this);}
String.prototype.capitalize = function() {
  return this.charAt(0).toUpperCase() + this.slice(1);
}

Date.prototype.yyyymmdd = function() {
  var mm = this.getMonth() + 1; // getMonth() is zero-based
  var dd = this.getDate();

  return [this.getFullYear(),
    (mm>9 ? '' : '0') + mm,
    (dd>9 ? '' : '0') + dd
  ].join('');
};

window.onerror = function (msg, url, line, column, error) {
  try{
    let url = Url.getCurrentUrl();
    if(msg && url && line && column && error)
    {
      Api.post('error-log', 'store', {
        message: msg,
        trace: error.stack,
        line: line,
        column: column,
        url: url
      }).call();
    }
  }catch(e){
    console.error(e)
  }
}

const store = configureStore()

const root = createRoot(document.getElementById('app'));

root.render(<BrowserRouter>
  <Provider store={store}>
    <PagesRouter />
  </Provider>
</BrowserRouter>);

